import React from 'react';
import {ICartItem} from '../../../../../../../../types/app.types';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../../../domain/specs';

export const TotalPrice = ({item}: {item: ICartItem}) => {
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    <div
      className={/* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.totalPrice : s.totalPriceViewport}
      data-hook={CartItemDataHook.TotalPrice}
      data-wix-item-total-price={CartItemDataHook.TotalPrice}>
      <div className={s.totalPriceText}>{item.convertedPrices.formattedTotalPrice}</div>
    </div>
  );
};
